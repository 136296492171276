import { axiosInstance } from '@config/axiosInstance';

export const getListArticles: any = async () => {
  const response = await axiosInstance.get('/guest/cms/articles');
  return response?.data;
};

export const getDetailArticles: any = async (id: string) => {
  const response = await axiosInstance.get(`/guest/cms/articles/${id}`);
  return response?.data;
};
