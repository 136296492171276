/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
// import CardArticles from '@components/partials/home/cardArticles';
// import FilterMultiHotels from '@components/baseComponents/filterMultiHotels';
// import HomeBanner from '@components/partials/home/homeBanner';
// import HomeBrand from '@components/partials/home/homeBrand';
// import Navbar from '@components/baseComponents/navbar';
import { HomeProps } from '@libs/typing';
import { withTranslation } from 'next-i18next';
import Link from 'next/link';
import Image from 'next/image';
import React, { useContext, useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { AuthContext } from '@context/authContext';
import Loader from 'react-loader-spinner';
import dynamic from 'next/dynamic';
import { getListArticles } from '@services/articles';
import { Router } from '../../i18n';
import styles from './styles.module.css';

const CardArticles = dynamic(() => import('@components/partials/home/cardArticles'));
const FilterMultiHotels = dynamic(() => import('@components/baseComponents/filterMultiHotels'));
const HomeBanner = dynamic(() => import('@components/partials/home/homeBanner'));
const HomeBrand = dynamic(() => import('@components/partials/home/homeBrand'));
const Navbar = dynamic(() => import('@components/baseComponents/navbar'));

const Index = (props: HomeProps) => {
  const { t } = props;
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isLoginOpen, setIsLoginOpen } = useContext(AuthContext);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  useEffect(() => {
    async function fetchAPI() {
      const res = await getListArticles();
      setArticles(res?.data);
    }
    fetchAPI();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div className="w-full h-screen flex justify-center items-center">
            <Loader type="Oval" color="#2673dd" height={75} width={75} timeout={10000} />
          </div>
        </>
      ) : (
        <>
          <Navbar />
          <div>
            <div
              className={`flex fixed items-center md:hidden bg-white w-full px-16 ${styles.shadowed} pb-4 pt-8 -mt-4`}
            >
              <Link href={'/' + t('lang')}>
                <p className=" text-base text-black font-semibold cursor-pointer list">{t('nav-1')}</p>
              </Link>
              <Link href={'/' + t('lang')}>
                <p className="ml-5 text-base text-black font-semibold cursor-pointer list">{t('nav-2')}</p>
              </Link>
              <Link href={'/' + t('lang')}>
                <p className="ml-5 text-base text-black font-semibold cursor-pointer list">{t('nav-3')}</p>
              </Link>
              <Link href={'/' + t('lang')}>
                <p className="ml-5 text-base text-black font-semibold cursor-pointer list">{t('nav-4')}</p>
              </Link>
              <Link href={'/' + t('lang')}>
                <p className="ml-5 text-base text-black font-semibold cursor-pointer list">
                  {t('nav-5')}
                  {' ' + process.env.BRAND}
                </p>
              </Link>
            </div>
          </div>
          <HomeBanner />
          <div className={`${styles.paddingMd} relative -mt-10 md:px-4 md:mt-4 sm:mt-4`}>
            <div className={`p-6 pb-3 bg-white rounded-md ${styles.checkHome} md:p-4`}>
              <FilterMultiHotels />
            </div>
          </div>
          <div className="px-16 mt-12 md:px-4 ">
            <div className="text-center">
              <p className="text-2xl font-bold md:text-xl">{t('section-two') + ' ' + process.env.BRAND}</p>
            </div>
            <div className="flex w-full items-end justify-center mt-12 md:mt-6">
              <div className="flex justify-center flex-col items-center ">
                <div className="md:hidden">
                  <Image height={50} width={100} src="/img/stars.svg" alt="stars" />
                </div>
                <div className="hidden md:block">
                  <Image height={50} width={75} src="/img/stars.svg" alt="stars" />
                </div>
                <p className="text-lg mt-6 md:text-sm text-center">{t('list-one')}</p>
              </div>
              <div className="flex justify-center flex-col items-center mx-24 md:mx-2">
                <Image height={50} width={70} src="/img/apps.svg" alt="apps" className="md:h-6" />
                <p className="text-lg mt-6 md:text-sm text-center">{t('list-two')}</p>
              </div>
              <div className="flex justify-center flex-col items-center ">
                <Image height={50} width={70} src="/img/phone.svg" alt="phones" className="md:h-6" />
                <p className="text-lg mt-6 md:text-sm text-center">{t('list-three')}</p>
              </div>
            </div>
          </div>
          <div className="px-16 mt-24 md:px-4">
            <div className={`flex w-full ${styles.bgSection3} items-center rounded-md p-16 md:p-4`}>
              <div className="w-8/12 md:w-full">
                <Image
                  src={process.env.LOGO_WHITE || '/img/logo-primary.svg'}
                  height={32}
                  width={100}
                  alt={`${process.env.BRAND}`}
                />
                <p className="text-2xl text-white font-bold mt-8 md:text-lg">
                  {process.env.BRAND + ' ' + t('description')}{' '}
                </p>
                <p className="text-xl mt-2 text-white">{t('join-now-get')}</p>
                <div className="flex mt-6">
                  <button
                    type="button"
                    onClick={() => setIsLoginOpen(!isLoginOpen)}
                    style={{ background: `${process.env.SECONDARY}` }}
                    className="flex items-center justify-center w-32 h-10 rounded text-base font-bold text-white mr-8 sm:px-5"
                  >
                    {t('sign-in')}
                  </button>
                  <button
                    type="button"
                    onClick={() => Router.push('/register')}
                    className={`sm:px-5 ${
                      t('join-now') === 'Join Now' ? 'w-32' : 'w-48'
                    } h-10 flex items-center justify-center bg-none rounded border border-white border-solid text-base font-bold text-white `}
                  >
                    {t('join-now')}
                  </button>
                </div>
              </div>
              <div className="w-4/12 flex justify-end md:hidden">
                <div className="-mb-20">
                  <Image src="/img/phones.svg" alt="imagePhones" height={300} width={400} />
                </div>
              </div>
            </div>
          </div>
          <div className="px-16 mt-24 md:px-4">
            <div className="text-center mb-12">
              <p className="text-2xl font-bold">{process.env.BRAND + ' Brand'}</p>
            </div>
            <HomeBrand />
            <HomeBrand />
          </div>
          <div className="px-16 mt-24 md:px-2">
            <div className="text-center mb-12">
              <p className="text-2xl font-bold">{t('latest-article')}</p>
            </div>
            <div className="flex md:hidden">
              {articles?.slice(0, 3).map((item) => (
                <div className="col-span-4">
                  <CardArticles data={item} />
                </div>
              ))}
            </div>
            <div id="articles" className="hidden md:block md:pb-10">
              <Carousel
                renderArrowPrev={(clickHandler) => {
                  return (
                    <>
                      <div onClick={clickHandler} className={styles.buttonPrev}>
                        <Image src="/img/prev.svg" alt="prev" height={32} width={32} />
                      </div>
                    </>
                  );
                }}
                renderArrowNext={(clickHandler) => {
                  return (
                    <>
                      <div onClick={clickHandler} className={styles.buttonNext}>
                        <Image src="/img/next.svg" alt="prev" height={32} width={32} />
                      </div>
                    </>
                  );
                }}
                showArrows={true}
              >
                <CardArticles />
                <CardArticles />
                <CardArticles />
              </Carousel>
            </div>
          </div>
        </>
      )}
    </>
  );
};
Index.getInitialProps = async () => ({
  namespacesRequired: ['home'],
});
export default withTranslation('home')(Index);
