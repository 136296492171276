import axios from 'axios';
import { Router } from 'i18n';

export const axiosInstance = axios.create({
  baseURL: process.env.BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  config.headers = Object.assign(
    {
      Authorization: `Bearer ${token}`,
    },
    config.headers
  );
  return config;
});
axiosInstance.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: any) {
    if (window.location.pathname.includes('/checkout')) {
      return error.response;
    }
    if (error?.response?.data?.meta?.error_code === 'NOT_FOUND_ERROR') {
      return Promise.reject(error);
    }
    alert(error);
    Router.replace('/500');
    return Promise.reject(error);
  }
);
